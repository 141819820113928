import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { AuthState } from '@state/auth/auth.state';
import { PlayState } from '@state/games/play/play.state';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private readonly authState: AuthState,
    private readonly playState: PlayState,
    private readonly router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const api = '{api}';
    let isGamePatch = false;
    if (req.url.includes(api) || req.url.includes(environment.apiUrl)) {
      let headers = this.authState.authorization;
      if (req.url.match(`{api}/gameplay((?!\\/teacher).)*$`)?.length) {
        isGamePatch = true;
        headers = this.playState.authorization;
      }
      const paramReq = req.clone({
        url: req.url.replace(api, environment.apiUrl),
        setHeaders: {
          ...headers,
        },
      });
      return next.handle(paramReq).pipe(
        tap({
          next: () => {},
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              if (isGamePatch) {
                this.playState.reset();
                if (this.router.url.includes('/game/play/enter-game')) {
                  return;
                }
                this.router.navigate(['/game/play/enter-game']);
                return;
              }
              this.authState.reset();
              this.router.navigate(['login']);
            }
          },
        }),
      );
    } else {
      return next.handle(req);
    }
  }
}

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();

  const googletagmanager = document.createElement('script');
  googletagmanager.async = true;
  googletagmanager.src = `https://www.googletagmanager.com/gtag/js?id=G-3SFKSZ9Y9P`;
  document.head.appendChild(googletagmanager);

  const dataLayer = document.createElement('script');
  dataLayer.innerHTML = ` window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-3SFKSZ9Y9P');`;
  document.head.appendChild(dataLayer);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

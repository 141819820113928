import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateService, CanActivateUserService } from './can-activate.service';
import { canActivateSuperAdmin } from '@shared/services/can-activate-super-admin.guard';

const routes: Routes = [
  {
    path: 'ui',
    loadChildren: () => import('./ui-kit-page/ui-kit-page.module').then((m) => m.UiKitPageModule),
  },
  {
    path: 'admin',
    canActivate: [canActivateSuperAdmin],
    loadChildren: () => import('./admin/admin.routes').then((m) => m.ADMIN_ROUTES),
  },
  {
    path: '',
    canActivate: [CanActivateService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/auth/pages/main/main.module').then((m) => m.MainModule),
      },
      {
        canActivate: [CanActivateUserService],
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'game',
        loadChildren: () => import('./pages/game/game.module').then((m) => m.GameModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
